import React, { useEffect, useState, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { Numbers } from '@buddy-technology/buddy_helpers';
import { createPurchasePayload } from '@buddy-technology/ion-helpers';

import { useDataContext } from '../context';
import { useEventContext } from '../context/EventContext';
import { RenderedView, StepThroughNav, IF } from './helpers';
import convertFieldIDtoRHF from '../utils/convertFieldIDtoRHF';
import { INVALID_DISPLAY_TYPES } from '../models/dictionary';
import Markdown from './UI/Markdown';
import Quote from './Quote';

function OfferOnly({
	hideFields,
	viewData,
	quoteViewData,
	quoteRequirements,
	partnerId,
	getValues,
	onOptIn,
	onOptOut,
	shouldHideBack,
	stage,
	...sharedProps
}) {
	const {
		control,
		errors,
		navigate,
		trigger,
		variables,
	} = sharedProps;

	const premium = useWatch({ control, name: 'policy::premiumTotal' });
	const { eventCallback } = useEventContext();
	const { offeringOptions, sessionId } = useDataContext();
	const [isChecked, setIsChecked] = useState(false);
	const hasUserMadeSelectionRef = useRef(false);

	const {
		id,
		backButtonLabel,
		content,
		invalidFieldDisplay,
		optInLabel = 'Add this insurance offer',
	} = viewData;

	const hasOfferOptions = content.some((c) => c.offerOptions);

	const handleOptIn = (payload) => {
		trigger().then((result) => {
			setIsChecked(result);
			if (result) {
				if (premium === 'Loading...') {
					// eslint-disable-next-line no-console
					console.warn('User has opted in before quote has resolved. Callback will be fired when quote resolves.');
					return;
				}
				onOptIn(payload);
			}
		});
	};

	const handleOptOut = (payload) => {
		setIsChecked(false);
		if (premium === 'Loading...') {
			// eslint-disable-next-line no-console
			console.warn('User has opted out before quote has resolved. Callback will be fired when quote resolves.');
			return;
		}
		onOptOut(payload);
	};

	const fireCallbacks = (choice) => {
		const eventDetails = {
			timestamp: Date.now(),
			viewId: 'offer-only',
			premium: Numbers.toUSD(premium),
			partnerId,
		};
		const callback = choice === 'OPT_IN' ? handleOptIn : handleOptOut;
		const eventType = choice === 'OPT_IN' ? 'onOptIn' : 'onOptOut';

		const payload = createPurchasePayload(getValues());
		if (!payload.session) {
			payload.session = {};
		}
		payload.session.sessionId = sessionId;

		eventCallback(eventType, eventDetails);
		callback(payload);
	};

	const handleChange = (event) => {
		let checked;
		if (event.target.type === 'checkbox') {
			checked = event.target.checked ? 'OPT_IN' : 'OPT_OUT';
		} else {
			checked = event.target.value;
		}
		hasUserMadeSelectionRef.current = true;
		fireCallbacks(checked);
	};

	useEffect(() => {
		if (invalidFieldDisplay === INVALID_DISPLAY_TYPES.OFFER_ONLY) {
			// if our invalid display type is OFFER_ONLY, we expect to display our fields within this view. Any fields that have a value and are invalid should show an error message, and the field should be highlighted.
			const state = getValues();
			const fieldsToTrigger = offeringOptions.invalidFields.reduce((acc, fieldId) => {
				const fieldValue = state[convertFieldIDtoRHF(fieldId)];
				if (fieldValue !== undefined && fieldValue !== '') {
					acc.push(fieldId);
				}
				return acc;
			}, []);

			trigger(fieldsToTrigger);
		}
		// Only fire this effect on mount.
	}, []);

	useEffect(() => {
		if (hasUserMadeSelectionRef.current) {
			const choice = isChecked ? 'OPT_IN' : 'OPT_OUT';
			fireCallbacks(choice);
		}
	}, [premium]);

	const isFormValid = Object.keys(errors).length === 0;

	return (
		<div id="offer-only-container">
			<RenderedView
				getValues={getValues}
				{...viewData}
				{...sharedProps}
				stage={stage}
				showNav={false}
			/>
			<Quote
				id="quote-view"
				offerOnlyMode
				partnerId={partnerId}
				quoteRequirements={quoteRequirements}
				getValues={getValues}
				stage={stage}
				{...quoteViewData}
				{...sharedProps}
				showNav={false}
			/>
			{/* the conditional block below is to support older implementations (optInLabel and default checkbox) */}
			<IF condition={!hasOfferOptions}>
				<div id="offer-only-input-wrapper">
					<input
						id="offer-only-checkbox"
						className="input form-checkbox"
						type="checkbox"
						data-testid="checkbox"
						checked={isChecked}
						disabled={!isFormValid}
						onChange={handleChange}
						tabIndex="0"
					/>
					<label
						id="offer-only-checkbox-label"
						htmlFor="offer-only-checkbox"
					>
						<Markdown
							getValues={getValues}
							variables={variables}
							text={viewData?.optInLabel || optInLabel}
							index="0"
							viewId="offer-only"
							control={control}
						/>
					</label>
				</div>
			</IF>
			<IF condition={!shouldHideBack}>
				<StepThroughNav
					backButtonLabel={backButtonLabel}
					navigate={navigate}
					hideNext
					status={id}
				/>
			</IF>
		</div>
	);
}

export default OfferOnly;
