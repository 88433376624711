import React, { useState, useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { Numbers } from '@buddy-technology/buddy_helpers';
import { createPurchasePayload } from '@buddy-technology/ion-helpers';

import { useDataContext } from '../context/DataContext';
import { useEventContext } from '../context/EventContext';
import IF from './helpers/IF';
import Markdown from './UI/Markdown';
import Label from './UI/InputLabel';

function OfferOptionsControls({
	viewData,
	partnerId,
	getValues,
	...sharedProps
}) {
	const {
		control,
		trigger,
		errors,
		variables,
	} = sharedProps;

	const premium = useWatch({ control, name: 'policy::premiumTotal' });
	const { offeringOptions, sessionId } = useDataContext();
	const { eventCallback, onOptIn, onOptOut } = useEventContext();
	const hasUserMadeSelectionRef = useRef(false);

	const {
		options = {},
	} = viewData;

	const {
		defaultOfferSelection = 'NOT_SELECTED', // 'OPT_IN' | 'OPT_OUT' | 'NOT_SELECTED'
		element = 'CHECKBOX', // 'CHECKBOX' or 'RADIO'
		isRequired = false, // ignored by 'CHECKBOX'
		label = 'Select Yes or No to continue',
		optInLabel = 'Yes',
		optOutLabel = 'No',
		disableWhileQuoteLoads = false,
	} = options;

	const [selectedOption, setSelectedOption] = useState(defaultOfferSelection);

	const shouldDisableInputs = disableWhileQuoteLoads && premium === 'Loading...';

	const handleOptIn = (payload) => {
		trigger().then((result) => {
			if (result) {
				setSelectedOption('OPT_IN');
				if (!premium || premium === 'Loading...') {
					// eslint-disable-next-line no-console
					console.warn('User has opted in before quote has resolved. Callback will be fired when quote resolves.');
					return;
				}
				onOptIn(payload);
			} else {
				setSelectedOption('OPT_OUT');
			}
		});
	};

	const handleOptOut = (payload) => {
		setSelectedOption('OPT_OUT');
		if (!premium || premium === 'Loading...') {
			// eslint-disable-next-line no-console
			console.warn('User has opted out before quote has resolved. Callback will be fired when quote resolves.');
			return;
		}
		onOptOut(payload);
	};

	const fireCallbacks = (choice) => {
		const eventDetails = {
			timestamp: Date.now(),
			viewId: 'offer-only',
			premium: Numbers.toUSD(premium),
			partnerId,
		};
		const callback = choice === 'OPT_IN' ? handleOptIn : handleOptOut;
		const eventType = choice === 'OPT_IN' ? 'onOptIn' : 'onOptOut';

		const payload = createPurchasePayload(getValues());
		if (!payload.session) {
			payload.session = {};
		}
		payload.session.sessionId = sessionId;

		eventCallback(eventType, eventDetails);
		callback(payload);
	};

	const handleChange = (event) => {
		let checked;
		if (event.target.type === 'checkbox') {
			checked = event.target.checked ? 'OPT_IN' : 'OPT_OUT';
		} else {
			checked = event.target.value;
		}
		hasUserMadeSelectionRef.current = true;
		fireCallbacks(checked);
	};

	const isIncomingDataValid = offeringOptions?.invalidFields?.length === 0;
	const isFormValid = Object.keys(errors).length === 0;

	// Automatically trigger addToCart action if defaultValue === 'OPT_IN'
	useEffect(() => {
		if (isIncomingDataValid && ['OPT_IN', 'OPT_OUT'].includes(defaultOfferSelection)) {
			const onLoadEventDetails = { target: { value: defaultOfferSelection } };
			handleChange(onLoadEventDetails);
		}
		if (!isIncomingDataValid) {
			setSelectedOption('OPT_OUT');
		}
	}, []);

	useEffect(() => {
		if (hasUserMadeSelectionRef.current) {
			fireCallbacks(selectedOption);
		}
	}, [premium]);

	return (
		<>
			<IF condition={element === 'CHECKBOX'}>
				<div id="offer-only-input-wrapper">
					<input
						id="offer-only-checkbox"
						className="input form-checkbox"
						defaultChecked={isIncomingDataValid && (defaultOfferSelection === 'OPT_IN')}
						checked={selectedOption === 'OPT_IN'}
						type="checkbox"
						disabled={!isFormValid || shouldDisableInputs}
						data-testid="checkbox"
						onChange={handleChange}
						tabIndex="0"
					/>
					<label
						id="offer-only-checkbox-label"
						htmlFor="offer-only-checkbox"
					>
						<Markdown
							getValues={getValues}
							variables={variables}
							text={viewData?.optInLabel || optInLabel}
							index="0"
							viewId="offer-only"
							control={control}
						/>
					</label>
				</div>
			</IF>
			<IF condition={element === 'RADIO'}>
				<div id="offer-only-input-wrapper">
					<Label
						labelFor="offer-only-radiogroup"
						isRequired={isRequired}
						getValues={getValues}
						variables={variables}
						viewId="offer-only"
						control={control}
					>
						{label}
					</Label>
					<div
						data-id="offer-only-yes-container"
					>
						<input
							id="offer-only-yes"
							defaultChecked={isIncomingDataValid && (defaultOfferSelection === 'OPT_IN')}
							type="radio"
							name="radio"
							checked={selectedOption === 'OPT_IN'}
							value="OPT_IN"
							disabled={!isFormValid || shouldDisableInputs}
							data-testid="offer-only-yes"
							className={`${selectedOption === 'OPT_IN' ? 'active' : ''}`}
							required={isRequired}
							tabIndex="0"
							onChange={handleChange}
						/>
						<label
							id="offer-only-checkbox-label-yes"
							htmlFor="offer-only-yes"
						>
							<Markdown
								getValues={getValues}
								variables={variables}
								text={optInLabel}
								index="0"
								viewId="offer-only"
								control={control}
							/>
						</label>
					</div>
					<div
						data-id="offer-only-no-container"
					>
						<input
							id="offer-only-no"
							defaultChecked={!isIncomingDataValid || defaultOfferSelection === 'OPT_OUT'}
							type="radio"
							name="radio"
							value="OPT_OUT"
							checked={selectedOption === 'OPT_OUT'}
							data-testid="offer-only-no"
							disabled={!isFormValid || shouldDisableInputs}
							className={`${selectedOption === 'OPT_OUT' ? 'active' : ''}`}
							required={isRequired}
							tabIndex="0"
							onChange={handleChange}
						/>
						<label
							id="offer-only-checkbox-label-yes"
							htmlFor="offer-only-no"
						>
							<Markdown
								getValues={getValues}
								variables={variables}
								text={optOutLabel}
								index="1"
								viewId="offer-only"
								control={control}
							/>
						</label>
					</div>
				</div>
			</IF>
		</>
	);
}

export default OfferOptionsControls;
